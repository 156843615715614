import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';
// import Button from '../components/common/Button';
import { Tooltip } from '@mui/material';
import {
  // Power,
  // Poll,
  AccountCircle,
  PowerSettingsNew,
  AccountTree,
  Description,
  PermContactCalendar,
  WarningRounded,
  Euro,
  // DonutSmall,
} from '@mui/icons-material';

import version from '../version';
import DashboardList from './DashboardList';
import SelectUserDashboardComponent from '../pages/SelectUserDashboard';
import { hasAdminMMSEntityNonRecursive } from '../utils/entity';
import { isDESMA, isDESMApremium } from '../utils/desma';
import { canApproveInvoice } from '../utils/jwt';

const SidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 1000;
  width: 300px;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  background: ${({ theme }) => theme.header.background};
  -webkit-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
`;
const Section = styled.div`
  margin-bottom: 8px;
`;
const LinkConn = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.secondaryDiv.color};
`;
const SectionTitle = styled.p`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin: 0.5rem 0 0.5rem 1.1rem;
`;
const SectionTitleDiv = styled.div`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin: 0.5rem 0 0.5rem 1.1rem;
`;
const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: 999;
`;
const LinksContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 300px;
  margin: 0 0 0 0;
`;
const LinkRow = styled.div`
  padding: 1rem 0 1rem 1rem;
  background: transparent;
  &:hover {
    background: ${({ theme }) => theme.secondaryDiv.backgroundHover};
  }
`;
const LinkRowNav = styled(LinkRow)`
  padding: 0;
  &:hover {
    background: transparent !important;
  }
`;
const LinkText = styled.span`
  color: ${({ theme }) => theme.secondaryDiv.color};
  vertical-align: middle;
`;
// const IconPower = styled(Power)`
//   color: ${({ theme }) => theme.secondaryDiv.color};
//   margin-right: 1rem;
//   vertical-align: middle;
// `;
// const IconType = styled(DonutSmall)`
//   color: ${({ theme }) => theme.secondaryDiv.color};
//   margin-right: 1rem;
//   vertical-align: middle;
// `;
// const IconPoll = styled(Poll)`
//   color: ${({ theme }) => theme.secondaryDiv.color};
//   margin-right: 1rem;
//   vertical-align: middle;
// `;
const IconBilling = styled(Euro)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconWarning = styled(WarningRounded)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconLogout = styled(PowerSettingsNew)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconAccount = styled(AccountCircle)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconSettings = styled(AccountTree)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconContact = styled(PermContactCalendar)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;
const IconImprint = styled(Description)`
  color: ${({ theme }) => theme.secondaryDiv.color};
  margin-right: 1rem;
  vertical-align: middle;
`;

const messages = defineMessages({
  LOGGED_IN_AS: {
    id: 'SideBar.LOGGED_IN_AS',
    defaultMessage: 'Logged in as:',
  },
  DASHBOARDS: {
    id: 'SideBar.DASHBOARDS',
    defaultMessage: 'My menu',
  },
  MAIN_PAGES: {
    id: 'SideBar.MAIN_PAGES',
    defaultMessage: 'Main pages',
  },
  STARTPAGE: {
    id: 'SideBar.STARTPAGE',
    defaultMessage: 'Startpage',
  },
  OVERVIEW: {
    id: 'SideBar.OVERVIEW',
    defaultMessage: 'Overview',
  },
  DEVICE_MANAGEMENT: {
    id: 'SideBar.DEVICE_MANAGEMENT',
    defaultMessage: 'Account Management',
  },
  USER_MANAGEMENT: {
    id: 'SideBar.CUSTOMER_MANAGEMENT',
    defaultMessage: 'User Management',
  },
  MDA: {
    id: 'SideBar.MDA',
    defaultMessage: 'MDA',
  },
  MMS: {
    id: 'SideBar.MMS',
    defaultMessage: 'MMS',
  },
  ALERTS: {
    id: 'ALERTS',
    defaultMessage: 'Alerts',
  },
  INFORMATION: {
    id: 'SideBar.INFORMATION',
    defaultMessage: 'Information',
  },
  PERSONAL_DATA: {
    id: 'SideBar.PERSONAL_DATA',
    defaultMessage: 'Personal data',
  },
  CONTACT: {
    id: 'SideBar.CONTACT',
    defaultMessage: 'Contact',
  },
  IMPRINT: {
    id: 'SideBar.IMPRINT',
    defaultMessage: 'Imprint',
  },
  TERMS_OF_USE: {
    id: 'SideBar.TERMS_OF_USE',
    defaultMessage: 'Terms of Use',
  },
  PRIVACY_STATEMENT: {
    id: 'SideBar.PRIVACY_STATEMENT',
    defaultMessage: 'Privacy Statement',
  },
  LOGOUT: {
    id: 'SideBar.LOGOUT',
    defaultMessage: 'Log out',
  },
  STATUS: {
    id: 'SideBar.STATUS',
    defaultMessage: 'Status',
  },
  MANAGEMENT: {
    id: 'SideBar.MANAGEMENT',
    defaultMessage: 'Management',
  },
  LABEL_NEW_DASHBOARD: {
    id: 'DashboardListPage.LABEL_NEW_DASHBOARD',
    defaultMessage: 'New',
  },
  PROFILE_SETTINGS: {
    id: 'ProfilePage.PROFILE_SETTINGS',
    defaultMessage: 'Profile settings',
  },
  LOAD_DASHBOARD: {
    id: 'SelectUserDashboardForm.LOAD_DASHBOARD',
    defaultMessage: 'Load dashboard',
  },
  CREATE_DASHBOARD: {
    id: 'DashboardCreatePage.CREATE_DASHBOARD',
    defaultMessage: 'Create dashboard',
  },
  BILLING: {
    id: 'SideBar.BILLING',
    defaultMessage: 'Billing',
  },
});

function Sidebar(props) {
  const {
    sidebarToggle,
    intl: { formatMessage },
    is_desma = false,
    is_desma_premium = false,
    config,
    is_distributor = false,
    has_admin_mms_account_entity,
    current_dashboard_id,
    user,
  } = props;

  const [showUserDashboardModal, setShowUserDashboardModal] = useState(false);

  let can_create_dashboard = true;

  if (is_desma && !is_desma_premium) {
    can_create_dashboard = false;
  }

  return (
    <>
      <SidebarContainer>
        <LinksContainer>
          {/* <SectionTitle>{formatMessage(messages.MAIN_PAGES)}</SectionTitle>
            <LinkConn to="/" onClick={() => sidebarToggle()}>
            <LinkRow>
            <IconList />
            <LinkText>{formatMessage(messages.STARTPAGE)}</LinkText>
            </LinkRow>
          </LinkConn> */}
          <div className="flex">
            <LinkConn to="/profile/" className="w-80" onClick={() => sidebarToggle()}>
              <LinkRow>
                <IconAccount />
                <LinkText>{user.fullname}</LinkText>
              </LinkRow>
            </LinkConn>
            <LinkConn to="/logout/" className="w-20">
              <Tooltip title={formatMessage(messages.LOGOUT)}>
                <LinkRow>
                  <IconLogout />
                </LinkRow>
              </Tooltip>
            </LinkConn>
          </div>
          <LinkConn to="/e/" onClick={() => sidebarToggle()}>
            <LinkRow>
              <IconSettings />
              <LinkText>{formatMessage(messages.MANAGEMENT)}</LinkText>
            </LinkRow>
          </LinkConn>
          {(is_distributor || has_admin_mms_account_entity) && (
            <Section>
              <LinkConn to="/mms/" onClick={() => sidebarToggle()}>
                <LinkRow>
                  <IconWarning />
                  <LinkText>
                    {is_desma ? formatMessage(messages.MMS) : formatMessage(messages.ALERTS)}
                  </LinkText>
                </LinkRow>
              </LinkConn>
            </Section>
          )}
          {canApproveInvoice() ? (
            <Section>
              <LinkConn to="/b/" onClick={() => sidebarToggle()}>
                <LinkRow>
                  <IconBilling />
                  <LinkText>{formatMessage(messages.BILLING)}</LinkText>
                </LinkRow>
              </LinkConn>
            </Section>
          ) : null}
          <Section>
            <SectionTitleDiv className="flex justify-between">
              <div className="w-95">{formatMessage(messages.DASHBOARDS)}</div>
              <div className="w-5">
                {can_create_dashboard && (
                  <LinkConn
                    className="fr mr2"
                    to="/dashboard/create/"
                    onClick={() => sidebarToggle()}
                  >
                    <Tooltip title={formatMessage(messages.CREATE_DASHBOARD)}>
                      <i className="fa fa-plus mr2" />
                    </Tooltip>
                  </LinkConn>
                )}
                {!is_desma && is_distributor && (
                  <>
                    <div
                      className="fr mr2 pointer"
                      onClick={() => {
                        setShowUserDashboardModal(!showUserDashboardModal);
                      }}
                    >
                      <Tooltip title={formatMessage(messages.LOAD_DASHBOARD)}>
                        <i className="fa fa-folder-open" />
                      </Tooltip>
                    </div>
                    <SelectUserDashboardComponent
                      showModal={showUserDashboardModal}
                      setShowModal={setShowUserDashboardModal}
                      sidebarToggle={sidebarToggle}
                    />
                  </>
                )}
              </div>
            </SectionTitleDiv>
            <LinkRowNav>
              <DashboardList
                sidebarToggle={sidebarToggle}
                current_dashboard_id={current_dashboard_id}
              />
            </LinkRowNav>
          </Section>
          <Section>
            <SectionTitle>{formatMessage(messages.INFORMATION)}</SectionTitle>
            {/* {can_admin && (
              <>
                <LinkConn to="/users/" onClick={sidebarToggle}>
                  <LinkRow>
                    <IconSettings />
                    <LinkText>{formatMessage(messages.USER_MANAGEMENT)}</LinkText>
                  </LinkRow>
                </LinkConn>
              </>
            )} */}
            {config.contact_url ? (
              <LinkConn to="/contact/" onClick={() => sidebarToggle()} target="_blank">
                <LinkRow>
                  <IconContact />
                  <LinkText>{formatMessage(messages.CONTACT)}</LinkText>
                </LinkRow>
              </LinkConn>
            ) : null}
            {config.privacy_url ? (
              <LinkConn to="/privacystatement/" onClick={() => sidebarToggle()} target="_blank">
                <LinkRow>
                  <IconImprint />
                  <LinkText>{formatMessage(messages.PRIVACY_STATEMENT)}</LinkText>
                </LinkRow>
              </LinkConn>
            ) : null}
            {config.tos_url ? (
              <LinkConn to="/termsofuse/" onClick={() => sidebarToggle()} target="_blank">
                <LinkRow>
                  <IconImprint />
                  <LinkText>{formatMessage(messages.TERMS_OF_USE)}</LinkText>
                </LinkRow>
              </LinkConn>
            ) : null}
            {config.imprint_url ? (
              <LinkConn to="/imprint/" onClick={() => sidebarToggle()} target="_blank">
                <LinkRow>
                  <IconImprint />
                  <LinkText>{formatMessage(messages.IMPRINT)}</LinkText>
                </LinkRow>
              </LinkConn>
            ) : null}
            <div className="flex justify-center grey-text pt3" style={{ color: 'LightGray' }}>
              {version.version}
            </div>
          </Section>
        </LinksContainer>
      </SidebarContainer>
      <Overlay id="overlay" />
    </>
  );
}

export default connect((state) => ({
  user: state.auth.user,
  config: state.config && state.config.data,
  has_admin_mms_account_entity: hasAdminMMSEntityNonRecursive(state.entity),
  is_desma: isDESMA(state.config),
  is_desma_premium: isDESMApremium(state.config),
}))(injectIntl(Sidebar));
